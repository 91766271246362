/** @format */

import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { Tab, Tabs } from "react-bootstrap";

function Stations() {
  return (
    <Tabs
      defaultActiveKey="local"
      id="stations"
      className="tabs"
      href="#stations"
    >
      <Tab eventKey="local" title="Local">
        <p className="tabs-sub">Spokane and North Idaho</p>
        <Accordion flush defaultActiveKey="1" className="accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Live Services</Accordion.Header>
            <Accordion.Body>
              <ul>
                <li>Saturday - Friday night study – KSPO 106.5FM @ 1PM</li>
                <li>Sunday - Sunday Service – KSPO 106.5FM @ 11AM</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Apply Within (ACN)</Accordion.Header>
            <Accordion.Body>
              Weekdays at 7:00AM :: 5:30PM :: 11:00PM
              <ul>
                <li>KSPO 106.5 FM</li>
                <li>KFIO 97.7 FM</li>
              </ul>
              Weekdays at 12:00PM (Noon) :: 5:30PM
              <ul>
                <li>KTRW 630 AM</li>
                <li>KTRW 96.5 FM</li>
              </ul>
            </Accordion.Body>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Apply Within (Calvary)</Accordion.Header>
              <Accordion.Body>
                Weekdays at 7:00AM :: 10:00AM
                <ul>
                  <li>KTWD 103.5 FM</li>
                  <li>KKRS 97.3 FM</li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion.Item>
        </Accordion>
      </Tab>
      <Tab eventKey="usa" title="USA">
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="0">
            <Accordion.Header>California (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>7:00AM</strong>

              <p>
                Banning
                <ul>KRTM 88.1 FM</ul>
              </p>
              <p>
                Fallbrook
                <ul>106.7 FM</ul>
              </p>
              <p>
                Hemet
                <ul>97.9 FM</ul>
              </p>
              <p>
                Merced
                <ul>KGDM 105.3 FM</ul>
              </p>
              <strong>7:30AM</strong>
              <p>
                San Francisco
                <ul>KDIA 1640 AM</ul>
              </p>
              <strong>10:00 AM</strong>
              <p>
                Banning
                <ul>KRTM 88.1 FM</ul>
              </p>
              <p>
                Hemet
                <ul>97.9 FM</ul>
              </p>
              <p>
                Fallbrook
                <ul>106.7 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Idaho (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>5:30AM</strong>
              <p>
                Grangeville
                <ul>90.5 FM</ul>
                Moscow/Pullman
                <ul>103.5 FM</ul>
              </p>
              <strong>6:30AM</strong>
              <p>
                Meridian
                <ul>97.5 FM</ul>
              </p>
              <strong>7:00AM :: 10:00AM</strong>
              <p>
                Wallace
                <ul>97.5 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Missouri (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>7:00AM :: 10:00AM</strong>
              <p>
                Bethany
                <ul>91.3 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>New Jersey (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>4:30 AM</strong>
              <p>
                Monmouth, Ocean County
                <ul>89.7 FM</ul>
              </p>
              <p>
                Southern Middlesex, Northern Monmouth County
                <ul>91.9 FM</ul>
              </p>
              <p>
                Northern Jersey
                <ul>103.1 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="5">
            <Accordion.Header>New York (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>4:30 AM</strong>
              <p>
                New York City
                <ul>95.1 FM</ul>
              </p>
              <p>
                Sullivan, Orange County
                <ul>99.7 FM</ul>
              </p>
              <p>
                Poughkeepsie
                <ul>106.9 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="8">
            <Accordion.Header>Texas (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>7:00AM :: 10:00AM</strong>
              <p>
                Commerce
                <ul>KYJC 91.3 FM</ul>
              </p>
              <p>
                Dallas/Forth Worth
                <ul>97.5 FM</ul>
                <ul>KDKR 91.3 FM</ul>
              </p>
              <p>
                Irving
                <ul>99.9 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Washington (ACN)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>7:00AM :: 5:30PM :: 11:00PM</strong>
              <p>
                Wenatchee, Central Washington
                <ul>KTBI 810 AM</ul>
              </p>
              <p>
                Yakima
                <ul>KYAK 930 AM</ul>
              </p>
              <p>
                Moses Lake, Othello, Central Washington
                <ul>KTAC 93.9 FM</ul>
              </p>
              <p>
                Tri-Cities, Walla Walla, Northeastern Oregon
                <ul>KGDN 101.3 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Washington (Calvary)</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>Weekdays</strong>
              </p>
              <strong>5:30AM</strong>
              <p>
                Pullman/Moscow
                <ul>103.5 FM</ul>
              </p>
              <strong>7:00AM :: 10:00AM</strong>
              <p>
                Davenport
                <ul>KKRS 97.3 FM</ul>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Tab>
      <Tab eventKey="international" title="International">
        <Accordion flush defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Uganda (Calvary)</Accordion.Header>
            <Accordion.Body>
              Weekdays at 10:00AM
              <ul>
                <li>Kampala - FM 92.7</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Tab>

      <Tab eventKey="sites" title="Stations">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Station Websites</Accordion.Header>
            <Accordion.Body>
              <p>
                <a href="https://www.acn.cc/">ACN Network</a>
              </p>
              <p>
                <a href="https://realhoperadio.com/">Real Hope Radio</a>
              </p>
              <p>
                <a href="https://higherrockradio.org">Higher Rock Radio</a>
              </p>
              <p>
                <a href="https://www.bridgeradio.org/">The Bridge</a>
              </p>
              <p>
                <a href="http://calvarychapelmerced.org/info.htm">
                  Kingdom Radio
                </a>
              </p>
              <p>
                <a href="https://calvarykla.com/radio-ministry/">
                  Calvary Chapel Kampala
                </a>
              </p>
              <p>
                <a href="https://kdia.com/">KDIA 1640 AM</a>
              </p>

              <p>
                <a href="http://www.kdkr.org/">Bible School Radio</a>
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Tab>
    </Tabs>
  );
}
export default Stations;
