/** @format */

import React from "react";

function Header() {
  return (
    <p className="top-bar">
      <a href="https://northcountrychapel.com">northcountrychapel.com</a>
    </p>
  );
}

export default Header;
