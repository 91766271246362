/** @format */

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import NavBar from "./NavBar";
import Program from "./Programs";
import Stations from "./Stations";
// import { Spinner } from "react-bootstrap";

function App() {
  return (
    <Router>
      <div>
        <Header />
        <NavBar />
        <Routes>
          <Route path="/" exact element={<Program />} />
          <Route path="/stations" element={<Stations />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
