/** @format */

import React from "react";
import { Navbar, Nav } from "react-bootstrap";
//import { Link } from "react-router-dom";
import aw_logo from "../images/aw_logo.png";

const NavBar = () => {
  return (
    <>
      <Navbar bg="light" expand="md">
        <Navbar.Brand href="/">
          <img src={aw_logo} alt="apply within logo" className="navbar-brand" />
        </Navbar.Brand>
        <Nav
          className="justify-content-end"
          // activeKey="/Header"
          expand="md"
        ></Nav>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="basic-navbar-nav navbar-collapse">
          <Nav className="ms-auto">
            <Nav.Item>
              <Nav.Link href="/">Home</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/stations">Stations</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
