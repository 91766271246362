/** @format */

import React from "react";
import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import ReactAudioPlayer from "react-audio-player";
import { Spinner } from "react-bootstrap";

let color = "#eeebe5";

function Program() {
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(true);
  const medialink = "https://media.northcountrychapel.com/rafiles/";

  useEffect(() => {
    fetch("https://api.applywithinradio.com/v1/programs")
      .then((response) => response.json())
      .then((program) => setPrograms(program))
      .catch((error) => {
        setLoading(true);
        console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="text-center spinner">
        <Spinner
          as="span"
          variant="secondary"
          size="lg"
          role="status"
          aria-hidden="true"
          animation="border"
        />
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="table">
      <Table striped hover>
        <thead>
          <tr>
            <th>Airdate</th>
            <th>Title</th>
            <th>Network</th>
            <th>Listen to the Full Study</th>
          </tr>
        </thead>

        {programs.map((program) => {
          const getBackgroundColor = () => {
            const day = new Date(program.airdate);
            const value = day.getDay();
            if (value % 2) {
              color = "#e9ecef";
            } else {
              color = "#ced4da";
            }
            return color;
          };

          return (
            <tbody key={program.id}>
              <tr style={{ backgroundColor: getBackgroundColor() }}>
                <td>{program.airdate}</td>
                <td>{program.title}</td>
                <td>{program.network}</td>
                <td>
                  <ReactAudioPlayer
                    className="audio-player"
                    src={`${medialink}${program.filename}`}
                    controls
                  />
                </td>
              </tr>
            </tbody>
          );
        })}
      </Table>
    </div>
  );
}

export default Program;
