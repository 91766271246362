/** @format */

import React from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Column from "react-bootstrap/esm/Col";
import aw_logo from "../images/aw_logo.png";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <Container className="footer" style={{ backgroundColor: "#eeebe5" }}>
      <Row>
        <Column xs lg="4"></Column>

        <Column xs lg="4">
          <div className="text-center">
            <p>
              <img src={aw_logo} alt="apply within logo" />
            </p>
            <p>
              {
                "Apply Within is the radio outreach ministry of North Country Chapel. We desire to reach out locally, nationally and internationally with the Word of God in fulfillment of the Great Commission, “and ye shall be witnesses unto me both in Jerusalem, and in all Judea, and in Samaria, and unto the uttermost part of the earth”."
              }
            </p>
            <p>Copyright © {currentYear}</p>
          </div>
        </Column>

        <Column xs lg="3"></Column>
      </Row>
    </Container>
  );
}

export default Footer;
